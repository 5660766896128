import React from "react"
import ChatButton from "../chatBtnPt"
import iconSaveSnafu from "../../../images/pt/icon-save-snafu-pt.png"

const SaveSnafuTab = () => {
  return (
    <>
      <div
        className="tab-pane fade"
        id="save-snafu"
        role="tabpanel"
        aria-labelledby="save-snafu-tab"
      >
        <div className="d-flex flex-column justify-content-center h-100">
          <figure className="mb-0 icon-main">
            {/* <StaticImage
              src="../../../images/icon-save-snafu.png"
              alt="Save Snafu"
              quality={100}
            /> */}
            <img src={iconSaveSnafu} alt="Save Snafu" className="img-fluid" />
          </figure>
          {/* <h3 className="text-uppercase">Não salvou e perdeu tudo</h3> */}
          <p>
            Você perdeu dias de progresso em um jogo com uma reinicialização
            fora de hora? Ou você confiou seu jogo a um amigo mais distraído? Ou
            você estava apenas com fome e não salvou o jogo antes de fazer algo
            colossalmente estúpido? Nós cobrimos isso. Não podemos ajudar a
            recuperar o que você perdeu, mas podemos te ajudar com algo
            delicioso para abafar essa choradeira - um Snickers.
          </p>
          <div className="text-center">
            <span className="text-uppercase">
              <ChatButton innerText="Converse com nossa agente agora" />
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default SaveSnafuTab
