import React from "react"

import TabLinks from "./tabLinksPt"
import NoobMoveTab from "./tab-content-pt/noobMoveTabPt"
import EarlyDeathTab from "./tab-content-pt/earlyDeathTabPt"
import RageQuitTab from "./tab-content-pt/rageQuitTabPt"
import SaveSnafuTab from "./tab-content-pt/saveSnafuTabPt"
import LootLossTab from "./tab-content-pt/lootLossPt"
import OtherBlundersTab from "./tab-content-pt/otherBlundersPt"

const HomeTabs = () => {
  return (
    <>
      <div className="text-center nav-tabs-header pt">
        <h2 className="text-uppercase">
          Já passou por alguma das situações abaixo?
        </h2>
        <TabLinks />
      </div>
      <div className="tab-content text-center text-white" id="myTabContent">
        <NoobMoveTab />
        <EarlyDeathTab />
        <RageQuitTab />
        <SaveSnafuTab />
        <LootLossTab />
        <OtherBlundersTab />
      </div>
    </>
  )
}

export default HomeTabs
