import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

// import snickersHungerInsuranceLogo from "../../images/snickers-hunger-insurance-pt.png"

const Logo = () => {
  return (
    <>
      <Link className="d-inline-block" to="/pt">
        <figure className="mb-0">
          <StaticImage
            src="../../images/snickers-hunger-insurance-pt.png"
            alt="Snickers Logo"
            quality={100}
          />
        </figure>
      </Link>
    </>
  )
}

export default Logo
